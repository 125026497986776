import * as React from 'react';
import { useState, useEffect } from 'react';
import { lazy, Suspense } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import '../../node_modules/font-awesome/scss/font-awesome.scss';
import Loader from './layout/Loader';
import ScrollToTop from './layout/ScrollToTop';
import routesOnePage from '../route';
import routes from '../routes';
import { Redirect } from 'react-router-dom';
import Config from '../config';
import axios from 'axios'

export const IsAuthContext = React.createContext()

const AdminLayout = lazy(() => import('./layout/AdminLayout'));

let baseAuthParam = {
    isauth:false,
    userid:false,
    name: false,
    companyName: false,
    companyId: false,
    groupName: false,
    groupId:false,
    roles: false
}

export function useIsLogin(initialValue = 'load',initialBaseAuth = baseAuthParam){
    const [authParam,setAuthParam] = useState(initialBaseAuth);
    const [isLogin, setIsLogin] = useState(initialValue)
    const [userData, setUserData] = useState({name:'',company:'',group:'',roles:[]})

    let cookieUserId = document.cookie.replace(/(?:(?:^|.*;\s*)id\s*\=\s*([^;]*).*$)|^.*$/, "$1");

    if(cookieUserId===''){
        cookieUserId=false
    }

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"/api/users/"+cookieUserId, {withCredentials: true}
            ).then((response) => {
                setIsLogin('yes')
                let getUserData = {
                    name: response.data.name,
                    company: response.data.company,
                    group: (response.data.usergroup) ? response.data.usergroup.name : '',
                    roles: response.data.roles
                }
                setUserData(getUserData)
                setAuthParam({
                    isauth:true,
                    userid:response.data.id,
                    name: response.data.name,
                    companyName: response.data.company.name,
                    companyId: response.data.company.id,
                    groupName: (response.data.usergroup) ? response.data.usergroup.name : '',
                    groupId:(response.data.usergroup) ? response.data.usergroup.id : '',
                    roles: response.data.roles
                })
            }).catch((error)=>{
                setIsLogin('no')
                setAuthParam({
                    isauth:false,
                    userid:false,
                    name: false,
                    companyName: false,
                    groupName: false,
                    roles: false
                })
            })
        })()
    }, [])
    
    return [isLogin, userData,authParam, setAuthParam];
}

function ProvideAuth({ children }) {
    const [isLogin, userData, authParam, setAuthParam] = useIsLogin();
    return (
      <IsAuthContext.Provider value={{authParam,setAuthParam}}>
        {children}
      </IsAuthContext.Provider>
    );
}

const App = () => {
    const location = useLocation();
    return (<>
        <ProvideAuth>
            <ScrollToTop>
                <Suspense fallback={<Loader />}>
                    <Route path={routesOnePage.map((x) => x.path)}>
                        <Switch location={location} key={location.pathname}>
                            {routesOnePage.map((route, index) => {
            return route.component ? (<Route key={index} path={route.path} exact={route.exact} render={(props) => <route.component {...props}/>}/>) : null;
        })}
                        </Switch>
                    </Route>
                    <Route path={routes.map((x) => x.path)}>
                        <AdminLayout />
                    </Route>
                    <Route path={'/'} exact>
                        <Redirect to={Config.defaultPath}/>
                    </Route>
                </Suspense>
            </ScrollToTop>
            <div className="backdrop"/>
        </ProvideAuth>
    </>);
};
export default App;
