module.exports = {
  en: {
    locale: 'en',
    messages: {
      signIn: 'Sign In',
      forgotPassword: 'Forgot password ?',
      emailAdress: 'Email Adress',
      password: 'Password',
      newPassword: 'New Password',
      resetPassword: 'Reset your password',
      validButton: 'Validate',
      returnToSignin: 'Return to signin page',
      connectFailed: 'Sorry, unable to reach the host server. Try Again.',
      identFailed: 'The identifiers are not valid',
      unknowError: 'Sorry, an unexpected error has occurred',
      navigationMenu: 'Navigation',
      dashboardMenu: 'Dashboard',
      dashboardMap: 'Map',
      userGroup: 'Users Group',
      userGroups: 'Users Groups',
      roles: 'Role',
      ROLE_SADMIN: 'Super Admin',
      ROLE_ADMIN: 'Admin',
      ROLE_GADMIN: 'Group Admin',
      ROLE_USER: 'User',
      editProfil: 'Edit profil',
      devicesMenu: 'Devices',
      deviceMenu: 'Device',
      devicesList: 'Devices List',
      monitoringMenu: 'Monitoring',
      clearance: 'Clearance',
      configurationMenu: 'Configuration',
      administrationMenu: 'Administration',
      reloadPage: 'Reload Page',
      notConnected: 'You are no longer connected',
      nameColumn: 'Name',
      metricsColumn: 'Metrics',
      metricsVirtualColumn: 'Virtual Metrics',
      temperature: 'Temperature',
      temperatureDefault: 'Default Temperature',
      temperatureRemote: 'Remote Temperature',
      temperatureAmbient: 'Ambient Temperature',
      acceleration_x: 'Acceleration X-Axis',
      acceleration_y: 'Acceleration Y-Axis',
      acceleration_z: 'Acceleration Z-Axis',
      humidity: 'Humidity',
      melody_id: 'Melody Id',
      melody_volume: 'Volume',
      melody_frequency: 'Frequency',
      consec_detect: 'Consecutive Detection',
      radar_interval: 'Radar Interval',
      water_detect: 'Rain',
      current: 'Current',
      current_energiot:'Current Energiot',
      current_fault: 'Current Fault',
      precipitation: 'Precipitation',
      pulse_1_total: 'Pulse',
      battery: 'Battery',
      atmospheric_pressure: 'Atmospheric Pressure',
      wind_speed: 'Wind Gust Speed',
      lastValue: 'Last value',
      filterLast24Hours: 'Last 24 hours',
      filterToday: 'Today',
      filterLast7Days: 'Last 7 days',
      filterThisWeek: 'Current Week',
      filterLast30Days: 'Last 30 days',
      filterThisMonth: 'Current Month',
      filterLast365Days: 'Last 365 Days',
      filterThisYear: 'Current Year',
      filterPersonal: 'Advanced',
      map: 'Map',
      userList: 'Users List',
      company: 'Company',
      companies: 'Companies',
      group: 'Group',
      role: 'Role',
      editUser: 'Edit User',
      userCreate: 'Create User',
      nbrUsers: 'Number of users',
      nbrGroups: 'Number of users groups',
      createCompany: 'Create Company',
      createUserGroup: 'Create a users group',
      createUser: 'Créate user',
      close: 'Close',
      validate: 'Validate',
      delete: 'Supprimer',
      confirmDeleteCompany: 'Are you sure that you want to delete this company ?',
      confirmDeleteUser: 'Are you sure that you want to delete this user ?',
      confirmDeleteDevice: 'Are you sure that you want to delete this device ?',
      irreversible: 'This action will be irreversible.',
      confirmDeleteUserGroup: 'Are you sure that you want to delete this users group ?',
      infosDeleteCompany: 'This action will delete all associated data (user groups, users, sensors) and will be irreversible.',
      infosDeleteUserGroup: 'This action will delete all associated data (users, sensors) and will be irreversible.',
      cancel: 'Cancel',
      actionCanceled: 'Action canceled',
      noRoles: 'No role',
      groupAdmin: 'Group Admin',
      none: 'None',
      warningNoGroups: 'Please note: this company does not currently have any users groups. You will therefore not be able to create users without a role or with an "Admin Group" role. To create a user with this type of role, you must first create a user group',
      myCompany: 'My Company',
      myGroup: 'My Users Group',
      createDevice: 'Create a device',
      elasticBase: 'Elastic Base',
      infosDiveres: 'Various information',
      successOperation: 'Operation completed successfullys',
      deviceAdministration: 'Devices',
      searchDevice: 'Search device',
      noCorrespondDevice: 'No device matches this search',
      byName: 'By name',
      byIdc: 'By IDC',
      byIndexElastic: 'By Elastic Index',
      priority: 'Priority',
      name:'Name',
      showErrorEventsBy:'Show events by NAME or IDC',
      dropCsv: 'Drop CSV file here or click to upload.',
      doublonCsv: 'Some columns of the csv file are used on multiple data',
      resetSearch:'Reset search',
      tableSearch:'Search',
      markerDraggable:'Marker is draggable',
      clickToDrag:'Click here to change marker position',
      version:'Version',
      filtre:'Filter',
      objectLinesMenu:'Line Object',
      create: 'Create',
      import: 'Import',
      createA: 'Crear a',
      t1ref: 'Tower reference 1',
      t2ref:'Tower reference 2',
      t1hg: 'Ground elevation tower 1',
      t2hg: 'Ground elevation tower 2',
      t1nv: 'Natural elevation tower 1',
      t2nv: 'Natural elevation tower 2',
      t1hc: 'Cable elevation tower 1',
      t2hc: 'Cable elevation tower 2',
      chg: 'Ground elevation at center',
      cnv: 'Natural elevation at center',
      chc: 'Cable elevation at center',
      id:'ID',
      btnInsert: 'Insert',
      btnUpdate: 'Update',
      shapeFile:'Shape File',
      fileName:'File name',
      zipFile:'Zip file',
      yes:'Yes',
      no:'No',
      canceled:'Canceled',
      typeDeleteX: 'Are you sure you want to delete ',
      edit:'Edit',
      attributeToGroup:'Attribute to a user group',
      icon:'Icon',
      pickColor:'Pick a color',
      lineNotImport:'However the following lines were not imported:',
      line:'Line',
      informations:'Informations',
      infoAndMap:'Infos and map',
      visualization:'Visualization',
      events:'Events',
      list:'List',
      section:'Section',
      noDataSoVerif:'It looks like there is no data. Contact the administrator to ensure that all metrics are configured correctly',
      phase:'Phase',
      selectALine:'Select a line',
      searchMetric:'Search a metric',
      seuil:'Limit',
      rule:'Rule',
      type:'Type',
      actions:'Actions',
      createMetricLimit:'Create a limit',
      equal:'Equal',
      higherOrEqual:'Higher or equal',
      lowerOrEqual:'Lower or equal',
      strictlyHigher:'Strictly higher',
      strictlyLower:'Strictly lower',
      warning:'Warning',
      critical:'Critical',
      metric:'Metric',
      seeMore:'See more',
      allCriticalState:'All critical states',
      allWarningState:'All warning states',
      allCriticalMetric:'All Metrics - Critical',
      allWarningMetric:'All Metrics - Warning',
      serviceDescription:'Service description',
      date:'Date',
      state:'State',
      weather:'Weather',
      degrees:'Degrees',
      gust:'Gust',
      windDirection:'Wind direction',
      north:'North',
      east:'East',
      south:'South',
      west:'West',
      northEast:'North-east',
      southEast:'South-east',
      northWest:'North-west',
      southWest:'South-west',
      cloudCover:'Cloud cover',
      Thunderstorm:'Stormy',
      Drizzle:'Ligth rain',
      Rain:'Rainy',
      Snow:'Snowy',
      Mist:'Mist',
      Smoke:'Smoke',
      Haze:'Haze',
      Dust:'Dust',
      Fog:'Fog',
      Sand:'Sand',
      Ash:'Ash',
      Squall:'Squall',
      Tornado:'Tornado',
      Clear:'Clear',
      Clouds:'Cloudy',
      description:'Description',
      dateOfStatement:'Date of statement',
      forecastFor:'Forecast for',
      perception:'Perception',
      onTheGround:'On the ground',
      forecast:'Forecast',
      forFiveDays:'For 5 days',
      hours:'Hours',
      Mon:'Monday',
      Tue:'Tuesday',
      Wed:'Wednesday',
      Thu:'Thursday',
      Fri:'Friday',
      Sat:'Saturday',
      Sun:'Sunday',
      noDelai:'No delay',
      minute:'Minutes',
      seuilInactive:'Inactivity threshold',
      tabData:'Data table',
      datas:'Datas',
      /**************** Table ****************/
      tableFirst: 'First',
      tableBack: 'Back',
      tableNext: 'Next',
      tableLast: 'Last',
      tableFistTitle: 'First Page',
      tableBackTitle: 'Pre Page',
      tableNextTitle: 'Next Page',
      tableLastTitle: 'Last Page',
      tableNoData: 'No data',
      /********** From Error ****************/
      emailUnique: 'A user already use this email adress',
      idcUnique: 'This IDC is already use by a device',
      nameUnique: 'This name is already in use',
      emailRequired: 'Email is required',
      elasticRequired: 'Elastic Base is required',
      indexElasticRequired: 'Elastic Index is required',
      timezoneRequired: 'Timezone is required',
      idcRequired: 'IDC is required',
      passwordRequired: 'Password is required',
      passwordMin8: 'The password must be at least 8 characters long',
      passwordMax50: 'Company name should not be more than 50 characters',
      companyRequired: 'Company name is required',
      companyMin2: 'The name of the company must be at least 2 characters long',
      companyMax255: 'Company name should not be more than 255 characters',
      usergroupsRequired: 'User Group name is required',
      usergroupsMin2: 'The name of the user group must be at least 2 characters long',
      usergroupsMax255: 'User Group name should not be more than 255 characters',
      nameRequired: 'Name is required',
      nameMin2: 'The name must be at least 2 characters long',
      nameMax255: 'Name should not be more than 255 characters',
      timezoneMin2: 'The Timezone must be at least 2 characters long',
      timezoneMax150: 'Timezone should not be more than 150 characters',
      forbiddenGeneral: 'You are not authorized to perform this action',
      noDeleteBecauseHaveUser: 'You cannot delete a group in which there are users',
      minLatMoins90:'Minimum latitude is -90',
      maxLat90:'Maximum latitude is 90',
      minLngMoins180:'Minimum longitude is -180',
      maxLng180:'Maximum longitude is 180',
      t1refRequired:'The reference of the first tower is required',
      t2refRequired:'The reference of the second tower is required',
      t1hgRequired:'Tower 1 ground elevation is required',
      t2hgRequired:'Tower 2 ground elevation is required',
      t1nvRequired:'Tower 1 nature elevation  is required',
      t2nvRequired:'Tower 2 nature elevation  is required',
      t1hcRequired:'Tower 1 cable elevation is required',
      t2hcRequired:'Tower 2 cable elevation is required',
      chgRequired:'Center ground elevation is required',
      t1hgFloat: 'Tower 1 ground elevation must de a float number',
      t2hgFloat: 'Tower 2 ground elevation must de a float number',
      t1nvFloat: 'Tower 1 nature elevation must de a float number',
      t2nvFloat: 'Tower 2 nature elevation must de a float number',
      t1hcFloat: 'Tower 1 cable elevation must de a float number',
      t2hcFloat: 'Tower 2 cable elevation must de a float number',
      chgFloat: 'Clearance ground elevation must de a float number',
      cnvFloat: 'Clearance nature elevation must de a float number',
      chcFloat: 'Clearance cable elevation must de a float number',
      t1refMin1: 'The reference of the first tower must be at least 1 characters long',
      t2refMin1: 'The reference of the second tower must be at least 1 characters long',
      t1refMax250: 'The reference of the first tower must be less than 250 characters',
      t2refMax250: 'The reference of the second tower must be less than 250 characters',
      fileRequired:'A file is required',
      seuilRequired:'The limit is required',
      ruleRequired:'The rule is required',
      typeRequired:'The type is required',
    }
  },
  fr: {
    locale: 'fr',
    messages: {
      signIn: 'Connexion',
      forgotPassword: 'Mot de passe Oublié ?',
      emailAdress: 'Adresse Email',
      password: 'Mot de passe',
      newPassword: 'Nouveau Mot de Passe',
      resetPassword: 'Nouveau mot de passe',
      validButton: 'Valider',
      returnToSignin: 'Retour à la page de connexion',
      connectFailed: 'Désolé, impossible de joindre le serveur hôte. Veuillez réessayer.',
      identFailed: 'Les identifiants ne sont pas valides',
      unknowError: 'Désolé, une erreur inattendue s\'est produite',
      navigationMenu: 'Navigation',
      dashboardMenu: 'Tableau de bord',
      dashboardMap: 'Carte',
      userGroup: 'Groupe d\'utilisateurs',
      userGroups: 'Groupes d\'utilisateurs',
      roles: 'Rôle',
      ROLE_SADMIN: 'Super Admin',
      ROLE_ADMIN: 'Admin',
      ROLE_GADMIN: 'Groupe Admin',
      ROLE_USER: 'Utilisateur',
      editProfil: 'Editer mon profil',
      devicesMenu: 'Capteurs',
      deviceMenu: 'Capteur',
      devicesList: 'Liste Capteurs',
      monitoringMenu: 'Supervision',
      clearance: 'Clearance',
      configurationMenu: 'Configuration',
      administrationMenu: 'Administration',
      reloadPage: 'Recharger la page',
      notConnected: 'Vous n\'êtes plus connecté',
      nameColumn: 'Nom',
      metricsColumn: 'Métriques',
      metricsVirtualColumn: 'Métriques Virtuelles',
      temperature: 'Température',
      temperatureDefault: 'Temperature par défaut',
      temperatureRemote: 'Temperature externe',
      temperatureAmbient: 'Temperature ambiente',
      acceleration_x: 'Acceleration X-Axis',
      acceleration_y: 'Acceleration Y-Axis',
      acceleration_z: 'Acceleration Z-Axis',
      humidity: 'Humidité',
      melody_id: 'Melodie Id',
      melody_volume: 'Volume',
      melody_frequency: 'Frequence',
      consec_detect: 'Détection consécutive',
      radar_interval: 'Interval Radar',
      water_detect: 'Pluie',
      current: 'Courant',
      current_energiot:'Courant Energiot',
      current_fault: 'Défaut courant',
      precipitation: 'Précipitation',
      pulse_1_total: 'Pulse',
      battery: 'Batterie',
      atmospheric_pressure: 'Pression Atmosphérique',
      wind_speed: 'Vitesse du Vent',
      lastValue: 'Dernière valeur',
      filterLast24Hours: 'Dernières 24 heures',
      filterToday: 'Aujourd\'hui',
      filterLast7Days: '7 derniers jours',
      filterThisWeek: 'Semaine en cours',
      filterLast30Days: '30 derniers jours',
      filterThisMonth: 'Mois en cours',
      filterLast365Days: '365 derniers jours',
      filterThisYear: 'Année en cours',
      filterPersonal: 'Avancé',
      map: 'Carte',
      userList: 'Liste des utilisateurs',
      company: 'Entreprise',
      companies: 'Entreprises',
      group: 'Groupe',
      role: 'Role',
      editUser: 'Edition Utilisateur',
      userCreate: 'Créer un Utilsateur',
      nbrUsers: 'Nombre d\'utilisateurs',
      nbrGroups: 'Nombre de groupes utilisateurs',
      createCompany: 'Créer une entreprise',
      createUserGroup: 'Créer un groupe d\'utilisateurs',
      createUser: 'Créer un utilisateur',
      close: 'Fermer',
      validate: 'Valider',
      users: 'Utilisateurs',
      delete: 'Supprimer',
      confirmDeleteCompany: 'Êtes-vous sur de vouloir supprimer cette entreprise ?',
      confirmDeleteUser: 'Êtes-vous sur de vouloir supprimer cet utilisateur ?',
      confirmDeleteDevice: 'Êtes-vous sur de vouloir supprimer ce device ?',
      irreversible: 'Cette action sera irreversible',
      confirmDeleteUserGroup: 'Êtes-vous sur de vouloir supprimer ce groupe d\'utilisateurs ?',
      infosDeleteCompany: 'Cette action supprimera l\'ensemble des données associées (groupes d\'utilisateurs, utilisateurs, capteurs) et sera irreversible.',
      infosDeleteUserGroup: 'Cette action supprimera l\'ensemble des données associées (utilisateurs, capteurs) et sera irreversible.',
      cancel: 'Annuler',
      actionCanceled: 'Action annulée',
      noRoles: 'Pas de rôle',
      groupAdmin: 'Groupe Admin',
      none: 'Aucun',
      warningNoGroups: 'Attention: cette entreprise ne possède pour le moment aucun groupe d\'utilisateurs. Vous ne pourrez donc pas créer d\'utilisateurs sans rôle ou avec un rôle de "Groupe Admin". Pour créer un utilisateur avec ce type de role, vous devez d\'abord créer un groupe d\'utilisateurs',
      myCompany: 'Mon Entreprise',
      myGroup: 'Mon Groupe d\'Utilisateurs',
      createDevice: 'Créer un device',
      elasticBase: 'Base Elastic',
      infosDiveres: 'Informations diverses',
      successOperation: 'Opération effectuée avec succès',
      deviceAdministration: 'Devices',
      searchDevice: 'Rechercher un device',
      noCorrespondDevice: 'Aucun device ne correspond à cette recherche',
      byName: 'Par nom',
      byIdc: 'Par IDC',
      byIndexElastic: 'Par Index Elastic',
      priority: 'Priorité',
      name:'Nom',
      showErrorEventsBy:'Afficher les events par NOM ou IDC',
      dropCsv: 'Déposez le fichier CSV ici ou cliquez pour télécharger.',
      doublonCsv: 'Certaines colones du fichier csv sont utilisées sur plusieurs données différentes',
      resetSearch:'Réinitialiser la recherche',
      tableSearch:'Rechercher',
      markerDraggable:'La position du marqueur est modifiable',
      clickToDrag:'Cliquer ici pour modifier la position du marqueur',
      version:'Version',
      filtre:'Filtre',
      objectLinesMenu:'Objet ligne',
      create: 'Créer',
      import: 'Importer',
      createA: 'Créer un',
      t1ref: 'Référence tour 1',
      t2ref:'Référence tour 2',
      t1hg: 'Tour 1 - Elévation :: Terrain',
      t2hg: 'Tour 2 - Elévation :: Terrain',
      t1nv: 'Tour 1 - Elévation :: Végétation',
      t2nv: 'Tour 2 - Elévation :: Végétation',
      t1hc: 'Tour 1 - Elévation :: Câble',
      t2hc: 'Tour 2 - Elévation :: Câble',
      chg: 'Clearance - Elévation :: Terrain',
      cnv: 'Clearance - Elévation :: Végétation',
      chc: 'Clearance - Elévation :: Câble',
      id:'ID',
      btnInsert: 'Insérer',
      btnUpdate: 'Mettre à jour',
      shapeFile:'Shape File',
      fileName:'Nom du fichier',
      zipFile:'Fichier zip',
      yes:'Oui',
      no:'Non',
      canceled: 'Annulé',
      typeDeleteX: 'Etes vous sûr de vouloir supprimer ',
      edit:'Editer',
      attributeToGroup:'Attribuer à un groupe d\'utilisateur',
      icon:'Icône',
      pickColor:'Sélectionner une couleur',
      lineNotImport:'Cependant les lignes suivantes n\'ont pas été importées:',
      line:'Ligne',
      informations:'Informations',
      infoAndMap:'Infos et carte',
      visualization:'Visualisation',
      events:'Événements',
      list:'Liste',
      section:'Section',
      noDataSoVerif:'Il semble qu\'il n\'y ait pas de données. Contactez l\'administrateur pour vous assurer que toutes les métriques sont correctement configurées',
      phase:'Phase',
      selectALine:'Sélectionner une ligne',
      searchMetric:'Rechercher une métrique',
      seuil:'Seuil',
      rule:'Règle',
      type:'Type',
      actions:'Actions',
      createMetricLimit:'Créer un seuil',
      equal:'Égal',
      higherOrEqual:'Supérieur ou égal',
      lowerOrEqual:'Inférieur ou égal',
      strictlyHigher:'Strictement supérieur',
      strictlyLower:'Strictement inférieur',
      warning:'Alerte',
      critical:'Critique',
      metric:'Métrique',
      seeMore:'Voir plus',
      allCriticalState:'Tous les états critique',
      allWarningState:'Tous les états alerte',
      allCriticalMetric:'Métriques - Etat critique',
      allWarningMetric:'Métriques - Etat d\'alerte',
      serviceDescription:'Description du service',
      date:'Date',
      state:'État',
      weather:'Météo',
      degrees:'Degrés',
      gust:'Rafale',
      windDirection:'Direction du vent',
      north:'Nord',
      east:'Est',
      south:'Sud',
      west:'Ouest',
      northEast:'Nord-est',
      southEast:'Sud-est',
      northWest:'Nord-ouest',
      southWest:'Sud-ouest',
      cloudCover:'Couverture nuageuse',
      Thunderstorm:'Orageux',
      Drizzle:'Bruine',
      Rain:'Pluvieux',
      Snow:'Enneigé',
      Mist:'Brume',
      Smoke:'Fumée',
      Haze:'Brume',
      Dust:'Poussière',
      Fog:'Brouillard',
      Sand:'Sable',
      Ash:'Cendre',
      Squall:'Rafale',
      Tornado:'Tornade',
      Clear:'Clair',
      Clouds:'Nuageux',
      description:'Description',
      dateOfStatement:'Date de relevé',
      forecastFor:'Prévision pour',
      perception:'Ressenti',
      onTheGround:'Au sol',
      forecast:'Prévision',
      forFiveDays:'Pour 5 jours',
      hours:'Heures',
      Mon:'Lundi',
      Tue:'Mardi',
      Wed:'Mercredi',
      Thu:'Jeudi',
      Fri:'Vendredi',
      Sat:'Samedi',
      Sun:'Dimanche',
      noDelai:'Aucun délai',
      minute:'Minutes',
      seuilInactive:'Seuil d\'inactivité',
      tabData:'Tableaux de données',
      datas:'Données',
      /**************** Table ****************/
      tableFirst: 'Début',
      tableBack: 'Précédent',
      tableNext: 'Suivant',
      tableLast: 'Dernière',
      tableFistTitle: 'Première Page',
      tableBackTitle: 'Page Précédente',
      tableNextTitle: 'Page Suivante',
      tableLastTitle: 'Dernière Page',
      tableNoData: 'Aucune donnée',
      /********** From Error ****************/
      emailUnique: 'Il existe déjà un utilisateur utilisant cetta adesse e-mail',
      idcUnique: 'Cet IDC est déjà utilisé par un device',
      nameUnique: 'Ce nom est déjà utilisé',
      emailRequired: 'Vous devez indiquer une adresse email',
      elasticRequired: 'Vous devez indiquer la base Elastic',
      indexElasticRequired: 'Vous devez indiquer l\'index Elastic',
      timezoneRequired: 'Vous devez indiquer la Timezone',
      idcRequired: 'Vous devez indiquer un IDC',
      passwordRequired: 'Vous devez indiquer un mot de passe',
      passwordMin8: 'Le mot de passe doit comporter au moins 8 caractères',
      passwordMax50: 'Le mot de passe ne doit pas comporter plus de 50 caractères',
      companyRequired: 'Vous devez indiquer le nom de l\'entreprise',
      companyMin2: 'Le nom de l\'entreprise doit comporter au moins 2 caractères',
      companyMax255: 'Le nom de l\'entreprise ne doit pas comporter plus de 255 caractères',
      usergroupsRequired: 'Vous devez indiquer le nom du groupe d\'utilisateurs',
      usergroupsMin2: 'Le nom du groupe d\'utilisateur doit comporter au moins 2 caractères',
      usergroupsMax255: 'Le nom  du groupe d\'utilisateur ne doit pas comporter plus de 255 caractères',
      nameRequired: 'Vous devez indiquer un nom',
      nameMin2: 'Le nom doit comporter au moins 2 caractères',
      nameMax255: 'Le nom  ne doit pas comporter plus de 255 caractères',
      timezoneMin2: 'La Timezone doit comporter au moins 2 caractères',
      timezoneMax150: 'La Timezone ne doit pas comporter plus de 150 caractères',
      forbiddenGeneral: 'Vous n\'êtes pas autorisé à effectuer cette action',
      noDeleteBecauseHaveUser: 'Vous ne pouvez pas supprimer un groupe dans lequel il y a des utilisateurs',
      minLatMoins90:'La latitude minimum est -90',
      maxLat90:'La latitude maximum est 90',
      minLngMoins180:'La longitude minimum est -180',
      maxLng180:'La longitude maximum est 180',
      t1refRequired:'La référence de la première tour est obligatoire',
      t2refRequired:'La référence de la seconde tour est obligatoire',
      t1hgRequired:'L\'élévation du sol de la tour 1 est obligatoire',
      t2hgRequired:'L\'élévation du sol de la tour 2 est obligatoire',
      t1nvRequired:'L\'élévation naturelle  de la tour 1 est obligatoire',
      t2nvRequired:'L\'élévation naturelle  de la tour 2 est obligatoire',
      t1hcRequired:'L\'élévation du câble de la tour 1 est obligatoire',
      t2hcRequired:'L\'élévation du câble de la tour 2 est obligatoire',
      chgRequired:'L\'élévation du sol au centre est obligatoire',
      t1hgFloat: 'L\'élévation du sol de la tour 1 doit avoir un nombre flottant',
      t2hgFloat: 'L\'élévation du sol de la tour 2 doit avoir un nombre flottant',
      t1nvFloat: 'L\'élévation naturelle de la tour 1 doit avoir un nombre flottant',
      t2nvFloat: 'L\'élévation naturelle de la tour 2 doit avoir un nombre flottant',
      t1hcFloat : 'L\'élévation du câble de la tour 1 doit avoir un nombre flottant',
      t2hcFloat : 'L\'élévation du câble de la tour 2 doit avoir un nombre flottant',
      chgFloat: 'Le dégagement au sol doit être un nombre flottant',
      cnvFloat: 'Le dégagement naturel doit être un nombre flottant',
      chcFloat: 'L\'élévation du sol au centre doit être un nombre flottant',
      t1refMin1: 'La référence de la première tour doit contenir au moins 1 caractère',
      t2refMin1: 'La référence de la second tour doit contenir au moins 1 caractère',
      t1refMax250: 'La référence de la première tour doit contenir au maximum 250 caractères',
      t2refMax250: 'La référence de la second tour doit contenir au maximum 250 caractères',
      fileRequired:'Un ficher est obligatoire',
      seuilRequired:'Le seuil est obligatoire',
      ruleRequired:'La règle est obligatoire',
      typeRequired:'Le type est obligatoire',
    },
  },
  es: {
    locale: 'es',
    messages: {
      signIn: 'Iniciar sesión',
      forgotPassword: '¿Olvidó su contraseña?',
      emailAdress: 'Dirección de correo electrónico',
      password: 'Contraseña',
      newPassword: 'Nueva contraseña',
      resetPassword: 'Nueva contraseña',
      validButton: 'Validar',
      returnToSignin: 'Volver a la página de inicio de sesión',
      connectFailed: 'Lo siento, no se pudo conectar con el servidor host. Intentar otra vez.',
      identFailed: 'Las credenciales no son válidas',
      unknowError: 'Lo sentimos, ha ocurrido un error inesperado',
      navigationMenu: 'Navegación',
      dashboardMenu: 'Panel',
      dashboardMap: 'Mapa',
      userGroup: 'Grupo de usuarios',
      userGroups: 'Grupos de usuarios',
      roles: 'Rol',
      ROLE_SADMIN: 'Superadministrador',
      ROLE_ADMIN: 'Administrador',
      ROLE_GADMIN: 'Administrador de grupo',
      ROLE_USER: 'Usuario',
      editProfil: 'Editar mi perfil',
      devicesMenu: 'Sensores',
      deviceMenu: 'Sensore',
      devicesList: 'Lista de sensores',
      monitoringMenu: 'Monitorización',
      clearance: 'liquidación',
      configurationMenu: 'Configuración',
      administrationMenu: 'Administración',
      reloadPage: 'Recargar página',
      notConnected: 'Ya no estás conectado',
      nameColumn: 'Nombre',
      metricsColumn: 'Métricas',
      metricsVirtualColumn: 'Métricas Virtual',
      temperature: 'Temperatura',
      temperatureDefault: 'Temperatura predeterminada',
      temperatureRemote: 'Temperatura externa',
      temperatureAmbient: 'Temperatura ambiente',
      acceleration_x: 'Aceleración X-Axis',
      acceleration_y: 'Aceleración Y-Axis',
      acceleration_z: 'Aceleración Z-Axis',
      humidity: 'Humedad',
      melody_id: 'Melodia Id',
      melody_volume: 'Volumen',
      melody_frequency: 'Frequencia',
      consec_detect: 'Detección consecutiva',
      radar_interval: 'Rango de radar',
      water_detect: 'Lluvia',
      current: 'Corriente',
      current_energiot:'Corriente Energiot',
      current_fault:'Corriente defecto',
      precipitation: 'Precipitación',
      pulse_1_total: 'Pulso',
      battery: 'Batería',
      atmospheric_pressure: 'Presion Atmosferica',
      wind_speed: 'Velocidad del viento',
      lastValue: 'Último valor',
      filterLast24Hours: 'Últimas 24 horas',
      filterToday: 'Hoy',
      filterLast7Days: 'Ultimos 7 días',
      filterThisWeek: 'Semana actual',
      filterLast30Days: 'Ultimos 30 días',
      filterThisMonth: 'Mes actual',
      filterLast365Days: 'Ultimos 365 días',
      filterThisYear: 'Año actual',
      filterPersonal: 'Avanzado',
      map: 'Mapa',
      userList: 'Lista de usuarios',
      company: 'Empresa',
      companies: 'Empresas',
      group: 'Grupo',
      role: 'Rol',
      editUser: 'Editar Usuario',
      userCreate: 'Crear usuario',
      nbrUsers: 'Número de usuarios',
      nbrGroups: 'Número de grupos de usuarios',
      createCompany: 'Crear empresa',
      createUserGroup: 'Crear grupo de usuarios',
      createUser: 'Crear usuario',
      close: 'Cerrar',
      validate: 'Validar',
      users: 'Usuarios',
      delete: 'Eliminar',
      confirmDeleteCompany: '¿Está seguro de que desea eliminar esta empresa?',
      confirmDeleteUser: '¿Está seguro de que desea eliminar este usuario?',
      confirmDeleteDevice: '¿Está seguro de que desea eliminar este dispositivo?',
      irreversible: 'Esta acción será irreversible',
      confirmDeleteUserGroup: '¿Está seguro de que desea eliminar este grupo de usuarios?',
      infosDeleteCompany: 'Esta acción eliminará todos los datos asociados (grupos de usuarios, usuarios, sensores) y será irreversible.',
      infosDeleteUserGroup: 'Esta acción eliminará todos los datos asociados (usuarios, sensores) y será irreversible.',
      cancel: 'Cancelar',
      actionCanceled: 'Acción cancelada',
      noRoles: 'Sin rol',
      groupAdmin: 'Administrador del grupo',
      none: 'Ninguno',
      warningNoGroups: 'Advertencia: Esta empresa actualmente no tiene ningún grupo de usuarios. Por lo tanto, no podrá crear usuarios sin un rol o con un rol de "Admin Group". Para crear un usuario con este tipo de rol, primero debe crear un grupo de usuarios',
      myCompany: 'Mi Empresa',
      myGroup: 'Mi grupo de usuarios',
      createDevice: 'Crear dispositivo',
      elasticBase: 'BaseElástica',
      infosDiveres: 'Información miscelánea',
      successOperation: 'Información miscelánea',
      deviceAdministration: 'Dispositivos',
      searchDevice: 'Buscar dispositivo',
      noCorrespondDevice: 'Ningún dispositivo coincide con esta búsqueda',
      byName: 'Por nombre',
      byIdc: 'Por IDC',
      byIndexElastic: 'Por índice elástico',
      priority: 'Prioridad',
      name:'nombre',
      showErrorEventsBy:'Mostrar eventos por NOMBRE o IDC',
      dropCsv: 'Suelte el archivo CSV aquí o haga clic para cargar',
      doublonCsv: 'Algunas columnas del archivo csv se usan en múltiples datos',
      resetSearch:'Reiniciar búsqueda',
      tableSearch:'Buscar',
      markerDraggable:'La posición del marcador es editable',
      clickToDrag:'Haga clic aquí para cambiar la posición del marcador',
      version:'Versión',
      filtre:'Filtrado',
      objectLinesMenu:'Linea de objeto',
      create: 'Crear',
      import: 'Importar',
      createA: 'Crear un',
      t1ref: 'Referencia de la torre 1',
      t2ref:'Referencia de la torre 2',
      t1hg: 'Torre de elevación del suelo 1',
      t2hg: 'Torre de elevación del suelo 2',
      t1nv: 'Torre de elevación natural 1',
      t2nv: 'Torre de elevación natural 2',
      t1hc: 'Elevación de cable de torre 1',
      t2hc: 'Elevación de cable de torre 2',
      chg: 'Elevación del suelo en el centro',
      cnv: 'Elevación natural en el centro',
      chc: 'Elevación de cables en el centro',
      id:'ID',
      btnInsert: 'Insertar',
      btnUpdate: 'Poner al día',
      shapeFile:'Shape File',
      fileName:'Nombre del expediente',
      zipFile:'Zip expediente',
      yes:'Sí',
      no:'No',
      canceled: 'Cancelado',
      typeDeleteX: 'Estas seguro que quieres borrarlo ',
      edit:'Editar',
      attributeToGroup:'Atribuir a un grupo de usuarios',
      icon:'Icono',
      pickColor:'Selecciona un color',
      lineNotImport:'Sin embargo, las siguientes líneas no se importaron:',
      line:'Línea',
      informations:'Informaciones',
      infoAndMap:'Infos y mapa',
      visualization:'Visualización',
      events:'Eventos',
      list:'Listado',
      section:'Sección',
      noDataSoVerif:'Parece que no hay datos. Póngase en contacto con el administrador para asegurarse de que todas las métricas estén configuradas correctamente',
      phase:'Fase',
      selectALine:'Seleccione una línea',
      searchMetric:'Buscar un métrica',
      seuil:'Límite',
      rule:'Regla',
      type:'Tipo',
      actions:'Accions',
      createMetricLimit:'Crear una límite',
      equal:'Igual',
      higherOrEqual:'Superior o igual',
      lowerOrEqual:'Inferior o igual',
      strictlyHigher:'Estrictamente superior',
      strictlyLower:'Estrictamente inferior',
      warning:'Alerta',
      critical:'Crítico',
      metric:'Métrica',
      seeMore:'Ver más',
      allCriticalState:'Todos los estados críticos',
      allWarningState:'Todos los estados alerta',
      allCriticalMetric:'Todas las métricas en estado crítico',
      allWarningMetric:'Todas las métricas en alerta',
      serviceDescription:'Descripción del servicio',
      date:'Fecha',
      state:'Estado',
      weather:'Clima',
      degrees:'Grados',
      gust:'Ráfaga',
      windDirection:'Dirección de viento',
      north:'Norte',
      east:'Este',
      south:'Sur',
      west:'Oeste',
      northEast:'Noreste',
      southEast:'Sureste',
      northWest:'Noroeste',
      southWest:'Sur oeste',
      cloudCover:'Cubierto de nubes',
      Thunderstorm:'Tormentoso',
      Drizzle:'llovizna',
      Rain:'lluvioso',
      Snow:'Nevado',
      Mist:'Bruma',
      Smoke:'Fumar',
      Haze:'Bruma',
      Dust:'Polvo',
      Fog:'Niebla',
      Sand:'Arena',
      Ash:'Ceniza',
      Squall:'Ráfaga',
      Tornado:'Tornado',
      Clear:'Luz',
      Clouds:'Nuboso',
      description:'Descripción',
      dateOfStatement:'Fecha de declaración',
      forecastFor:'Previsión para',
      perception:'Percepción',
      onTheGround:'En el suelo',
      forecast:'Previsión',
      forFiveDays:'Por 5 días',
      hours:'horas',
      Mon:'Lunes',
      Tue:'Martes',
      Wed:'Miércoles',
      Thu:'Jueves',
      Fri:'Viernes',
      Sat:'Sábado',
      Sun:'Domingo',
      noDelai:'Sin demora',
      minute:'Minutos',
      seuilInactive:'Umbral de inactividad',
      tabData:'Tabla de datos',
      datas:'Datos',
      /**************** Table ****************/
      tableFirst: 'Inicio',
      tableBack: 'Anterior',
      tableNext: 'Siguiente',
      tableLast: 'Último',
      tableFistTitle: 'Primera página',
      tableBackTitle: 'Página anterior',
      tableNextTitle: 'Página siguiente',
      tableLastTitle: 'Última página',
      tableNoData: 'Sin datos',
      /********** From Error ****************/
      emailUnique: 'Ya hay un usuario usando esta dirección de correo electrónico',
      idcUnique: 'Este IDC ya lo usa un dispositivo',
      nameUnique: 'Este nombre ya está en uso',
      emailRequired: 'Debe proporcionar una dirección de correo electrónico',
      elasticRequired: 'Debe especificar la base elástica',
      indexElasticRequired: 'Debe especificar el índice elástico',
      timezoneRequired: 'Debe especificar la zona horaria',
      idcRequired: 'Debe especificar un IDC',
      passwordRequired: 'Debe proporcionar una contraseña',
      passwordMin8: 'La contraseña debe tener al menos 8 caracteres',
      passwordMax50: 'La contraseña no debe tener más de 50 caracteres',
      companyRequired: 'Debe proporcionar el nombre de la empresa',
      companyMin2: 'El nombre de la empresa debe tener al menos 2 caracteres',
      companyMax255: 'El nombre de la empresa no debe exceder los 255 caracteres',
      usergroupsRequired: 'Debe especificar el nombre del grupo de usuarios',
      usergroupsMin2: 'El nombre del grupo de usuarios debe tener al menos 2 caracteres',
      usergroupsMax255: 'El nombre del grupo de usuarios no debe exceder los 255 caracteres',
      nameRequired: 'Debe proporcionar un nombre',
      nameMin2: 'El nombre debe tener al menos 2 caracteres',
      nameMax255: 'El nombre no debe tener más de 255 caracteres',
      timezoneMin2: 'La zona horaria debe tener al menos 2 caracteres',
      timezoneMax150: 'La zona horaria no debe tener más de 150 caracteres',
      forbiddenGeneral: 'No está autorizado para realizar esta acción',
      noDeleteBecauseHaveUser: 'No puede eliminar un grupo en el que hay usuarios',
      minLatMoins90:'La latitud mínima es -90',
      maxLat90:'La latitud máxima es 90',
      minLngMoins180:'La longitud mínima es -180',
      maxLng180:'La longitud máxima es 180',
      t1refRequired:'La referencia de la primera torre es obligatoria',
      t2refRequired:'La referencia de la segunda torre es obligatoria',
      t1hgRequired:'La elevación del suelo de la torre 1 es obligatoria',
      t2hgRequired:'La elevación del suelo de la torre 2 es obligatoria',
      t1nvRequired:'La elevación natural  de la torre 1 es obligatoria',
      t2nvRequired:'La elevación natural  de la torre 2 es obligatoria',
      t1hcRequired:'La elevación du câble de la torre 1 es obligatoria',
      t2hcRequired:'La elevación du câble de la torre 2 es obligatoria',
      chgRequired:'La elevación del suelo en el centro es obligatoria',
      t1hgFloat: 'La elevación del piso de la torre 1 debe tener un número flotante',
      t2hgFloat: 'La elevación del piso de la torre 2 debe tener un número flotante',
      t1nvFloat: 'La elevación natural de la Torre 1 debe tener un número flotante',
      t2nvFloat: 'La elevación natural de la Torre 2 debe tener un número flotante',
      t1hcFloat: 'La elevación del cable de la torre 1 debe tener un número flotante',
      t2hcFloat: 'La elevación del cable de la torre 2 debe tener un número flotante',
      chgFloat: 'La distancia al suelo debe ser un número flotante',
      cnvFloat: 'La elevacion naturaleza de la autorización debe ser un número flotante',
      chcFloat: 'La elevación del cable de espacio libre debe tener un número flotante',
      t1refMin1: 'La referencia de la primera torre debe contener al menos 1 carácter',
      t2refMin1: 'La referencia de la segunda torre debe contener al menos 1 carácter',
      t1refMax250: 'La referencia de la primera torre debe contener un máximo de 250 caracteres',
      t2refMax250: 'La referencia de la segunda torre debe contener un máximo de 250 caracteres',
      fileRequired:'Se requiere un archivo',
      seuilRequired:'El límite es obligatorio',
      ruleRequired:'La regla es obligatorio',
      typeRequired:'El tipo es obligatorio',
    },
  }
}
