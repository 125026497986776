import * as React from 'react';
import {useState, useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App/index';
import * as serviceWorker from './serviceWorker';
import config from './config';
import './assets/scss/style.scss';
import { CookiesProvider, useCookies} from 'react-cookie';
//translation
import translations from './translations'
import { IntlProvider } from 'react-redux-multilingual'
import { IntlActions } from 'react-redux-multilingual'
//endtranslation
import { store } from './store/store';
import axios from 'axios'
require('dotenv').config()

const uninterceptedAxiosInstance = axios.create();

axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
        const originalConfig = error.config;
        if (error.response) {
            if (error.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;

                try {
                    const refreshToken = localStorage.getItem('refresh_token')
                    const rs = await uninterceptedAxiosInstance.post(process.env.REACT_APP_API_URL+"/api/token/refresh", {
                        refresh_token: refreshToken
                    },{withCredentials: true}).then((response) => {
                        if(response.data.refresh_token){
                            localStorage.setItem('refresh_token',response.data.refresh_token)
                        }
                    })
                    return axios(originalConfig);
                } catch (_error) {
                    if (_error.response && _error.response.data) {
                        return Promise.reject(_error.response.data);
                    }

                    return Promise.reject(_error);
                }
            }
        }
      return Promise.reject(error);
    }
);

var nameEQ = "lang=";
var ca = document.cookie.split(';');
for(var i=0;i < ca.length;i++) {
    var c = ca[i];
    while (c.charAt(0)===' ') c = c.substring(1,c.length);
    if (c.indexOf(nameEQ) === 0) store.dispatch(IntlActions.setLocale(c.substring(nameEQ.length,c.length)));
}

export function useChangeLang(initialLang = 'en'){
    const [lang,setLang] = useState(initialLang)
    const [cookies, setCookie] = useCookies()
    
    const langToggle = function (valeur)  {
        setLang(v => valeur)
        store.dispatch(IntlActions.setLocale(valeur))
        let current_date = new Date()
        let timestampdate = current_date.setDate(current_date.getDate() + 90)
        let expire_date = new Date(timestampdate)
        setCookie('lang', valeur, { path: '/', expires: expire_date});
    }

    return [
        lang,
        langToggle
    ]
}

const app = (
        <Provider store={store}>
            <BrowserRouter basename={config.basename}>
                <CookiesProvider>
                    <IntlProvider translations={translations}>
                        <App />
                    </IntlProvider>
                </CookiesProvider>
            </BrowserRouter>
        </Provider>);
ReactDOM.render(app, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
