import * as React from 'react';
const DashboardDefault      = React.lazy(() => import('./Component/Dashboard/Default'));
const DevicesList           = React.lazy(() => import('./Component/Devices/DevicesList'));
const DeviceDetailsMaster   = React.lazy(() => import('./Component/Devices/DeviceDetailsMaster'));
const MonitoringDefault     = React.lazy(() => import('./Component/Monitoring/Default'));
const MonitoringMap         = React.lazy(() => import('./Component/Monitoring/Map'));
const MonitoringClearance   = React.lazy(() => import('./Component/Monitoring/Clearance'));
const ConfigurationDefault  = React.lazy(() => import('./Component/Configuration/Default'));
const Company               = React.lazy(() => import('./Component/Configuration/Company'));
const CompanyEdit           = React.lazy(() => import('./Component/Configuration/Company/CompanyEdit'));
const UserGroupEdit         = React.lazy(() => import('./Component/Configuration/UsersGroups/UserGroupEdit'));
const UserEdit              = React.lazy(() => import('./Component/Configuration/Users/UserEdit'));
const CreateDevice          = React.lazy(() => import('./Component/Configuration/Devices/CreateDevice'));
const SearchDevice          = React.lazy(() => import('./Component/Configuration/Devices/SearchDevice'));
const DeviceDetails         = React.lazy(() => import('./Component/Configuration/Devices/DeviceDetails'));
const ObjectLinesCreate     = React.lazy(() => import('./Component/Configuration/ObjectLines/ObjectLinesCreate'));
const ObjectLinesImport     = React.lazy(() => import('./Component/Configuration/ObjectLines/ObjectLinesImport'));
const ObjectLinesList       = React.lazy(() => import('./Component/Configuration/ObjectLines/ObjectLinesList'));
const ObjectLinesEdit       = React.lazy(() => import('./Component/Configuration/ObjectLines/ObjectLinesEdit'));
const ObjectLinesChart      = React.lazy(() => import('./Component/Configuration/ObjectLines/ObjectLinesChart'));
const ShapeFile             = React.lazy(() => import('./Component/Configuration/ShapeFile/ShapeFile'));
//const CreateMetric          = React.lazy(() => import('./Component/Configuration/Metric/CreateMetric'));
const SearchMetric          = React.lazy(() => import('./Component/Configuration/Metric/SearchMetric'));

const routes = [
    { path: '/dashboard/default', exact: true, name: 'Dashboard', component: DashboardDefault },
    { path: '/devices/list', exact: true, name: 'DevicesList', component: DevicesList },
    { path: '/device/:id', exact: true, name: 'DeviceDetailsMaster', component: DeviceDetailsMaster },
    { path: '/monitoring/default', exact: true, name: 'Monitoring', component: MonitoringDefault },
    { path: '/monitoring/map', exact: true, name: 'MonitoringMap', component: MonitoringMap },
    { path: '/monitoring/clearance', exact: true, name: 'MonitoringClearance', component: MonitoringClearance },
    { path: '/configuration/default', exact: true, name: 'Configuration', component: ConfigurationDefault },
    { path: '/configuration/company', exact: true, name: 'Company', component: Company },
    { path: '/configuration/company/:id', exact: true, name: 'CompanyEdit', component: CompanyEdit },
    { path: '/configuration/usergroup/:id', exact: true, name: 'UserGroupEdit', component: UserGroupEdit },
    { path: '/configuration/user/:id', exact: true, name: 'UserEdit', component: UserEdit },
    { path: '/configuration/createdevice', exact: true, name: 'CreateDevice', component: CreateDevice },
    { path: '/configuration/searchdevice', exact: true, name: 'SearchDevice', component: SearchDevice },
    { path: '/configuration/devicedetails/:id', exact: true, name: 'DeviceDetails', component: DeviceDetails },
    { path: '/configuration/objectlines/create', exact: true, name: 'ObjectLinesCreate', component: ObjectLinesCreate },
    { path: '/configuration/objectlines/import', exact: true, name: 'importObjectLines', component: ObjectLinesImport },
    { path: '/configuration/objectlines/list', exact: true, name: 'ObjectLinesList', component: ObjectLinesList },
    { path: '/configuration/objectlines/edit', exact: true, name: 'ObjectLinesEdit', component: ObjectLinesEdit },
    { path: '/configuration/objectlines/chart/:id', exact: true, name: 'ObjectLinesChart', component: ObjectLinesChart },
    { path: '/configuration/shapefile', exact: true, name: 'ShapeFile', component: ShapeFile },
    //{ path: '/configuration/metric/create', exact: true, name: 'CreateMetric', component: CreateMetric },
    { path: '/configuration/metric/search', exact: true, name: 'SearchMetric', component: SearchMetric },
];
export default routes;
